import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1.8em;
`;

const Badges = styled.div`
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 3px;

  & > div {
    margin-left: 5px;
    margin-bottom: 5px;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 768px) {
      &:first-child {
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Badge = styled.div`
  padding: 6px;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const About = styled.div`
  margin-top: 40px;
`;

const AboutTitle = styled.h2``;

const Link = styled.a`
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const Contacts = styled.div`
  display: flex;
  margin-top: 50px;

  a {
    text-decoration: underline;
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Contact = styled.div`
  font-size: 18px;
`;

const Separate = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-size: 40px;
  line-height: 0;
`;

export default () => {
  return (
    <Content>
      <TitleWrapper>
        <Title>✌ Hi, I'm Maxim Malykhin</Title>

        <Badges>
          <Badge>Fullstack</Badge>
          <Badge>TypeScript</Badge>
          <Badge>Node.js</Badge>
          <Badge>Next.js</Badge>
        </Badges>
      </TitleWrapper>

      <About>
        <AboutTitle>About my experience:</AboutTitle>
        <p>- Since 2013 as a freelancer</p>
        <p>- Since 2016 in product teams</p>
        <p>
          - In 2019-2020 at the{' '}
          <Link
            href="https://www.hermitageshop.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hermitage Shop
          </Link>
        </p>
        <p>
          - In 2019-2020 at{' '}
          <Link
            href="https://gotovokitchen.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gotovo Kitchen
          </Link>
        </p>
        <p>
          - In 2020-2021 at{' '}
          <Link
            href="https://www.siemens-energy.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Siemens Energy
          </Link>
        </p>
        <p>
          - In 2022 at{' '}
          <Link
            href="https://www.linkedin.com/company/gotiggy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tiggy
          </Link>
        </p>
        <p>
          - In 2022-2023 at{' '}
          <Link
            href="https://www.linkedin.com/company/food-rocketme/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Food Rocket
          </Link>
        </p>
        <p>
          - In 2024 at{' '}
          <Link
            href="https://tawasal.ae/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tawasal SuperApp
          </Link>
        </p>

        <p>My journey continues and I am looking for new experiences!</p>
      </About>

      <Contacts>
        <Contact>
          <a
            href="https://www.linkedin.com/in/mxmalykhin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </Contact>

        <Separate>·</Separate>

        <Contact>
          <a
            href="https://t.me/MxMalykhin"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
        </Contact>

        <Separate>·</Separate>

        <Contact>
          <a
            href="https://github.com/mxmalykhin"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
        </Contact>
      </Contacts>
    </Content>
  );
};
