import React from 'react';
import styled from 'styled-components';

import Intro from './Intro';

const AppWrapper = styled.main`
  background: #000;
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  min-height: 100vh;
  padding: 0 10px;
`;

export default () => {
  return (
    <AppWrapper>
      <Intro />
    </AppWrapper>
  );
};
